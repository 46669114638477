<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>
          Quotation # {{ quotation.jobNumber }}
          <span class="float-right">Add documents</span>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 pt-0 pb-0">
        <p>
          <router-link
            custom v-slot="{ navigate }"
            :to="`/quotations/view/${quotation.id}`">
            <v-btn
              small
              @click="navigate"
              @keypress.enter="navigate"
              role="link"
              class="relative"
              style="top:-3px">
                View quotation
            </v-btn>
          </router-link>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 col-md-4">
        <v-text-field
          hint="Add a title for the document"
          persistent-hint
          label="Title"
          v-model="title" />
      </v-col>
      <v-col class="col-12 col-md-5">
        <v-checkbox
          label="Show to crews"
          v-model="forCrews"
          hint="Tick this box if you want the document to be visible to work crews"
          persistent-hint />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 col-md-10">
        <div class="uploads"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Transloadit from '@uppy/transloadit'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

export default {
  name: 'AddDocuments',
  data() {
    return {
      quotation: {},
      title: null,
      forCrews: false,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  methods: {
    getQuotation() {
      const quotationId = this.$route.params.id;
      axios.get(`/quotations/getSingle/${quotationId}.json?token=${this.token}`)
        .then((response) => {
          this.quotation = response.data;
        });
    },
  },
  mounted() {
    this.getQuotation();
    const uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
      },
    });
    uppy.use(Dashboard, {
      inline: true,
      width: '100%',
      target: '.uploads',
      proudlyDisplayPoweredByUppy: false,
    })
    .use(Transloadit, {
      service: 'https://api2.transloadit.com',
      params: {
        auth: { key: 'db6a1d40944f11e5a0a165fb32f6e49b' },
        template_id: 'f73197cf10564cdf814d34642589e166',
      },
      waitForEncoding: true,
      waitForMetadata: false,
      importFromUploadURLs: false,
      alwaysRunAssembly: false,
      signature: null,
      fields: {},
      limit: 0,
    })
    .on('complete', (results) => {
      const quotationId = this.$route.params.id;
      const postData = {};
      postData.results = results;
      postData.title = this.title;
      postData.forCrews = this.forCrews;
      axios.post(`/quotationDocuments/addDocument/${quotationId}.json?token=${this.token}`, postData)
        .then((response) => {
          this.title = null,
          this.forCrews = false,
          uppy.reset();
          console.log(response.data);  
        });
    });
  },
};
</script>

<style lang="scss">
.uppy-Dashboard-inner {
  max-height: 350px!important;
}
</style>
